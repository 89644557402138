// tslint:disable max-line-length
// tslint:disable no-namespace
// tslint:disable max-classes-per-file
// tslint:disable trailing-comma
// tslint:disable no-string-literal
// tslint:disable no-shadowed-variable array-type
/* eslint-disable */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any
}

export type AddressInput = {
    country: Scalars['String']
    state: Scalars['String']
    zip: Scalars['String']
    city: Scalars['String']
    line1: Scalars['String']
}

export type BookableOutput = {
    __typename?: 'BookableOutput'
    id: Scalars['ID']
    venueId: Scalars['String']
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
}

export type BookingEditable = {
    __typename?: 'BookingEditable'
    isEditable: Scalars['Boolean']
    price: Scalars['Float']
}

export enum BookingStatus {
    InProgress = 'IN_PROGRESS',
    Cancelled = 'CANCELLED',
    Completed = 'COMPLETED',
}

export type BusinessHours = {
    __typename?: 'BusinessHours'
    id: Scalars['ID']
    dayOfWeek: Scalars['Float']
    openTime: Scalars['String']
    closeTime: Scalars['String']
    courtId: Scalars['String']
    court: Court
    venueId: Scalars['String']
    venue: Venue
}

export type BusinessHoursInput = {
    openTime: Scalars['String']
    closeTime: Scalars['String']
    dayOfWeek: Scalars['Float']
}

export type Court = {
    __typename?: 'Court'
    id: Scalars['ID']
    name: Scalars['String']
    surface: CourtSurface
    type: CourtType
    lights: Scalars['Boolean']
    usableDuringWinter: Scalars['Boolean']
    price: Scalars['Float']
    venueId: Scalars['String']
    venue: Venue
    bookings: Array<Booking>
    businessHours: Array<BusinessHours>
}

export enum CourtSurface {
    Clay = 'CLAY',
    Hard = 'HARD',
    Carpet = 'CARPET',
    Grass = 'GRASS',
}

export enum CourtType {
    Outdoor = 'OUTDOOR',
    Indoor = 'INDOOR',
}

export type EditProfileInput = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    gender?: Maybe<Scalars['String']>
    country?: Maybe<Scalars['String']>
    zip?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    line1?: Maybe<Scalars['String']>
}

export type Event = {
    __typename?: 'Event'
    id: Scalars['ID']
    title: Scalars['String']
    description: Scalars['String']
    venueId: Scalars['String']
    createdAt: Scalars['DateTime']
    venue: Venue
}

export enum GenderEnum {
    NotProvided = 'NOT_PROVIDED',
    Woman = 'WOMAN',
    Man = 'MAN',
}

export type Image = {
    __typename?: 'Image'
    id: Scalars['ID']
    url: Scalars['String']
    venueId: Scalars['String']
    venue: Venue
}

export type Location = {
    __typename?: 'Location'
    location: Scalars['String']
    count: Scalars['Int']
}

export type LoginInput = {
    email: Scalars['String']
    password: Scalars['String']
}

export type Mutation = {
    __typename?: 'Mutation'
    createVenue: Venue
    register: Scalars['String']
    socialAuth: Scalars['String']
    login: Scalars['String']
    changePassword: Scalars['Boolean']
    editProfile: User
    getSmsValidationByPhone: Scalars['Boolean']
    validate: Scalars['Boolean']
    createBooking: Booking
    editBooking: Booking
    removeBooking: Scalars['Boolean']
    removeBookingByAdmin: Scalars['Boolean']
    storePartnerContact: Scalars['Boolean']
}

export type MutationCreateVenueArgs = {
    input: VenueInput
}

export type MutationRegisterArgs = {
    input: RegisterInput
}

export type MutationSocialAuthArgs = {
    input: SocialAuthInput
}

export type MutationLoginArgs = {
    input: LoginInput
}

export type MutationChangePasswordArgs = {
    newPasswordAgain: Scalars['String']
    newPassword: Scalars['String']
    currentPassword: Scalars['String']
}

export type MutationEditProfileArgs = {
    input: EditProfileInput
}

export type MutationGetSmsValidationByPhoneArgs = {
    lastName: Scalars['String']
    firstName: Scalars['String']
    phone: Scalars['String']
}

export type MutationValidateArgs = {
    code: Scalars['String']
}

export type MutationCreateBookingArgs = {
    repeat?: Maybe<Scalars['Float']>
    courtId?: Maybe<Scalars['String']>
    comment: Scalars['String']
    price: Scalars['Float']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    venueId: Scalars['String']
}

export type MutationEditBookingArgs = {
    comment?: Maybe<Scalars['String']>
    courtId: Scalars['String']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    bookingId: Scalars['String']
}

export type MutationRemoveBookingArgs = {
    bookingId: Scalars['String']
}

export type MutationRemoveBookingByAdminArgs = {
    bookingId: Scalars['String']
}

export type MutationStorePartnerContactArgs = {
    analiticsId?: Maybe<Scalars['String']>
    mail: Scalars['String']
}

export type Price = {
    __typename?: 'Price'
    id: Scalars['ID']
    dayOfWeek: Scalars['Float']
    from: Scalars['String']
    to: Scalars['String']
    price: Scalars['Float']
    venueId: Scalars['String']
    venue: Venue
}

export type Query = {
    __typename?: 'Query'
    getCourts: Array<Court>
    getCourtsOfVenue: Array<Court>
    getCourtsOfMyVenue: Array<Court>
    getBusinessHours: Array<BusinessHours>
    getBusinessHoursOfVenue: Array<BusinessHours>
    getBusinessHoursOfMyVenue: Array<BusinessHours>
    getBusinessHoursOfVenueAtDay: Array<BusinessHours>
    getVenue: Venue
    getVenues: Array<Venue>
    getMyVenue: Venue
    searchVenues: Array<Venue>
    searchLocationsAndVenues: Array<SearchResult>
    getVenueTypes: Array<VenueType>
    me: User
    getRolesOfUser: Array<Role>
    getPrices: Array<Price>
    getPricesOfVenue: Array<Price>
    getPricesOfVenueAtDay: Array<Price>
    getPriceOfBooking: Scalars['Int']
    canCreateBooking: Scalars['Boolean']
    canEditBooking: BookingEditable
    getBookings: Array<Booking>
    getBookingsOfMyVenue: Array<Booking>
    getBookingsOfUser: Array<Booking>
    getBookableSlots: Array<BookableOutput>
    getMemberRole: Role
    getMyEvents: Array<Event>
    getImages: Array<Image>
    getImagesOfVenue: Array<Image>
}

export type QueryGetCourtsOfVenueArgs = {
    venueId: Scalars['String']
}

export type QueryGetBusinessHoursOfVenueArgs = {
    venueId: Scalars['String']
}

export type QueryGetBusinessHoursOfVenueAtDayArgs = {
    dayOfWeek: Scalars['Float']
    venueId: Scalars['String']
}

export type QueryGetVenueArgs = {
    idOrSlug: Scalars['String']
}

export type QuerySearchVenuesArgs = {
    keyword?: Maybe<Scalars['String']>
}

export type QuerySearchLocationsAndVenuesArgs = {
    keyword?: Maybe<Scalars['String']>
}

export type QueryGetRolesOfUserArgs = {
    userId: Scalars['String']
}

export type QueryGetPricesOfVenueArgs = {
    venueId: Scalars['String']
}

export type QueryGetPricesOfVenueAtDayArgs = {
    dayOfWeek: Scalars['Float']
    venueId: Scalars['String']
}

export type QueryGetPriceOfBookingArgs = {
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    venueId: Scalars['String']
}

export type QueryCanCreateBookingArgs = {
    repeat?: Maybe<Scalars['Float']>
    courtId?: Maybe<Scalars['String']>
    comment: Scalars['String']
    price: Scalars['Float']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    venueId: Scalars['String']
}

export type QueryCanEditBookingArgs = {
    courtId: Scalars['String']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    bookingId: Scalars['String']
}

export type QueryGetBookingsOfMyVenueArgs = {
    to?: Maybe<Scalars['DateTime']>
    from?: Maybe<Scalars['DateTime']>
}

export type QueryGetBookableSlotsArgs = {
    length: Scalars['Float']
    date: Scalars['DateTime']
    venueId: Scalars['String']
}

export type QueryGetImagesOfVenueArgs = {
    venueId: Scalars['String']
}

export type RegisterInput = {
    email: Scalars['String']
    password: Scalars['String']
}

export type Role = {
    __typename?: 'Role'
    id: Scalars['ID']
    name: Scalars['String']
    rank: Scalars['Float']
    users: Array<User>
}

export type SearchResult = Location | Venue

export type SeasonBooking = {
    __typename?: 'SeasonBooking'
    id: Scalars['ID']
    bookings: Array<Booking>
}

export type SocialAuthInput = {
    provider: Scalars['String']
    accessToken?: Maybe<Scalars['String']>
    code?: Maybe<Scalars['String']>
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    gender: GenderEnum
    email: Scalars['String']
    address?: Maybe<Address>
    source: Scalars['String']
    validated: Scalars['Boolean']
    phone?: Maybe<Scalars['String']>
    avatar?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    bookings?: Maybe<Array<Booking>>
    venue?: Maybe<Venue>
    roles: Array<Role>
}

export type Venue = {
    __typename?: 'Venue'
    id: Scalars['ID']
    name: Scalars['String']
    venueTypeId: Scalars['String']
    venueType: VenueType
    slug: Scalars['String']
    companyName: Scalars['String']
    description: Scalars['String']
    phoneNumber: Scalars['String']
    contactPersonName: Scalars['String']
    email: Scalars['String']
    ownerId: Scalars['String']
    owner: User
    address: Address
    businessHours: Array<BusinessHours>
    images: Array<Image>
    prices: Array<Price>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    courts: Array<Court>
    minimumBookingMinutes: Scalars['Float']
    unitBookingMinutes: Scalars['Float']
    startMinutesOfBookings: Scalars['String']
    events: Array<Event>
}

export type VenueInput = {
    name: Scalars['String']
    companyName: Scalars['String']
    phoneNumber: Scalars['String']
    contactPersonName: Scalars['String']
    email: Scalars['String']
    addressInput: AddressInput
    businessHoursInput: Array<BusinessHoursInput>
    price: Scalars['Float']
}

export type VenueType = {
    __typename?: 'VenueType'
    id: Scalars['ID']
    name: Scalars['String']
    venues: Array<Venue>
}

export type Address = {
    __typename?: 'address'
    id: Scalars['ID']
    country?: Maybe<Scalars['String']>
    state?: Maybe<Scalars['String']>
    zip?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    line1?: Maybe<Scalars['String']>
    lat?: Maybe<Scalars['Float']>
    lng?: Maybe<Scalars['Float']>
}

export type Booking = {
    __typename?: 'booking'
    id: Scalars['ID']
    userId: Scalars['String']
    user: User
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
    paid: Scalars['Boolean']
    status: BookingStatus
    createdAt: Scalars['DateTime']
    validUntil: Scalars['DateTime']
    completedAt: Scalars['DateTime']
    comment: Scalars['String']
    courtId: Scalars['ID']
    court: Court
    seasonBookingId?: Maybe<Scalars['String']>
    seasonBooking?: Maybe<SeasonBooking>
}

export type RegisterMutationVariables = Exact<{
    input: RegisterInput
}>

export type RegisterMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'register'>

export type LoginMutationVariables = Exact<{
    input: LoginInput
}>

export type LoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'login'>

export type ChangePasswordMutationVariables = Exact<{
    currentPw: Scalars['String']
    newPw: Scalars['String']
    newPwAgain: Scalars['String']
}>

export type ChangePasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'changePassword'>

export type EditProfileMutationVariables = Exact<{
    input: EditProfileInput
}>

export type EditProfileMutation = { __typename?: 'Mutation' } & {
    editProfile: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'gender'> & {
            address?: Maybe<{ __typename?: 'address' } & Pick<Address, 'country' | 'zip' | 'city' | 'line1'>>
        }
}

export type SocialAuthMutationVariables = Exact<{
    input: SocialAuthInput
}>

export type SocialAuthMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'socialAuth'>

export type GetPhoneValidationMutationVariables = Exact<{
    phone: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
}>

export type GetPhoneValidationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'getSmsValidationByPhone'>

export type ValidateAccountMutationVariables = Exact<{
    code: Scalars['String']
}>

export type ValidateAccountMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'validate'>

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query' } & {
    me: { __typename?: 'User' } & Pick<
        User,
        'id' | 'firstName' | 'lastName' | 'email' | 'gender' | 'avatar' | 'validated' | 'phone'
    > & { address?: Maybe<{ __typename?: 'address' } & Pick<Address, 'country' | 'zip' | 'line1' | 'city'>> }
}

export type GetBookingsOfUserQueryVariables = Exact<{ [key: string]: never }>

export type GetBookingsOfUserQuery = { __typename?: 'Query' } & {
    getBookingsOfUser: Array<
        { __typename?: 'booking' } & Pick<Booking, 'id' | 'from' | 'to' | 'price'> & {
                court: { __typename?: 'Court' } & Pick<Court, 'name'> & {
                        venue: { __typename?: 'Venue' } & Pick<Venue, 'name'>
                    }
            }
    >
}

export type StorePartnerMutationVariables = Exact<{
    mail: Scalars['String']
    analiticsId?: Maybe<Scalars['String']>
}>

export type StorePartnerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'storePartnerContact'>

export type VenuesQueryVariables = Exact<{
    keyword?: Maybe<Scalars['String']>
}>

export type VenuesQuery = { __typename?: 'Query' } & {
    searchVenues: Array<
        { __typename?: 'Venue' } & Pick<
            Venue,
            'id' | 'name' | 'slug' | 'companyName' | 'description' | 'phoneNumber' | 'contactPersonName' | 'email'
        > & {
                businessHours: Array<
                    { __typename?: 'BusinessHours' } & Pick<BusinessHours, 'dayOfWeek' | 'openTime' | 'closeTime'>
                >
                images: Array<{ __typename?: 'Image' } & Pick<Image, 'url'>>
                address: { __typename?: 'address' } & Pick<
                    Address,
                    'id' | 'lat' | 'lng' | 'line1' | 'city' | 'zip' | 'state' | 'country'
                >
            }
    >
}

export type VenueQueryVariables = Exact<{
    idOrSlug: Scalars['String']
}>

export type VenueQuery = { __typename?: 'Query' } & {
    getVenue: { __typename?: 'Venue' } & Pick<
        Venue,
        'id' | 'name' | 'slug' | 'companyName' | 'phoneNumber' | 'contactPersonName' | 'description' | 'email'
    > & {
            images: Array<{ __typename?: 'Image' } & Pick<Image, 'url'>>
            courts: Array<{ __typename?: 'Court' } & Pick<Court, 'surface' | 'name'>>
            address: { __typename?: 'address' } & Pick<
                Address,
                'id' | 'lat' | 'lng' | 'line1' | 'city' | 'zip' | 'state' | 'country'
            >
        }
}

export type SearchLocationAndVenueQueryVariables = Exact<{
    keyword?: Maybe<Scalars['String']>
}>

export type SearchLocationAndVenueQuery = { __typename?: 'Query' } & {
    searchLocationsAndVenues: Array<
        | ({ __typename?: 'Location' } & Pick<Location, 'location' | 'count'>)
        | ({ __typename?: 'Venue' } & Pick<Venue, 'id' | 'name' | 'slug'> & {
                  images: Array<{ __typename?: 'Image' } & Pick<Image, 'url'>>
              })
    >
}

export type GetBookableSlotsQueryVariables = Exact<{
    venueId: Scalars['String']
    date: Scalars['DateTime']
    length: Scalars['Float']
}>

export type GetBookableSlotsQuery = { __typename?: 'Query' } & {
    getBookableSlots: Array<
        { __typename?: 'BookableOutput' } & Pick<BookableOutput, 'id' | 'venueId' | 'from' | 'to' | 'price'>
    >
}

export type CreateBookingMutationVariables = Exact<{
    venueId: Scalars['String']
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
}>

export type CreateBookingMutation = { __typename?: 'Mutation' } & {
    createBooking: { __typename?: 'booking' } & Pick<Booking, 'id'>
}

export type CanCreateBookingQueryVariables = Exact<{
    venueId: Scalars['String']
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
}>

export type CanCreateBookingQuery = { __typename?: 'Query' } & Pick<Query, 'canCreateBooking'>

export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
        register(input: $input)
    }
`
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options)
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
        login(input: $input)
    }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPw: String!, $newPw: String!, $newPwAgain: String!) {
        changePassword(currentPassword: $currentPw, newPassword: $newPw, newPasswordAgain: $newPwAgain)
    }
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPw: // value for 'currentPw'
 *      newPw: // value for 'newPw'
 *      newPwAgain: // value for 'newPwAgain'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>
export const EditProfileDocument = gql`
    mutation EditProfile($input: EditProfileInput!) {
        editProfile(input: $input) {
            id
            firstName
            lastName
            email
            gender
            address {
                country
                zip
                city
                line1
            }
        }
    }
`
export type EditProfileMutationFn = Apollo.MutationFunction<EditProfileMutation, EditProfileMutationVariables>

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<EditProfileMutation, EditProfileMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument, options)
}
export type EditProfileMutationHookResult = ReturnType<typeof useEditProfileMutation>
export type EditProfileMutationResult = Apollo.MutationResult<EditProfileMutation>
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<EditProfileMutation, EditProfileMutationVariables>
export const SocialAuthDocument = gql`
    mutation SocialAuth($input: SocialAuthInput!) {
        socialAuth(input: $input)
    }
`
export type SocialAuthMutationFn = Apollo.MutationFunction<SocialAuthMutation, SocialAuthMutationVariables>

/**
 * __useSocialAuthMutation__
 *
 * To run a mutation, you first call `useSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialAuthMutation, { data, loading, error }] = useSocialAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSocialAuthMutation(
    baseOptions?: Apollo.MutationHookOptions<SocialAuthMutation, SocialAuthMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<SocialAuthMutation, SocialAuthMutationVariables>(SocialAuthDocument, options)
}
export type SocialAuthMutationHookResult = ReturnType<typeof useSocialAuthMutation>
export type SocialAuthMutationResult = Apollo.MutationResult<SocialAuthMutation>
export type SocialAuthMutationOptions = Apollo.BaseMutationOptions<SocialAuthMutation, SocialAuthMutationVariables>
export const GetPhoneValidationDocument = gql`
    mutation getPhoneValidation($phone: String!, $firstName: String!, $lastName: String!) {
        getSmsValidationByPhone(phone: $phone, firstName: $firstName, lastName: $lastName)
    }
`
export type GetPhoneValidationMutationFn = Apollo.MutationFunction<
    GetPhoneValidationMutation,
    GetPhoneValidationMutationVariables
>

/**
 * __useGetPhoneValidationMutation__
 *
 * To run a mutation, you first call `useGetPhoneValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPhoneValidationMutation, { data, loading, error }] = useGetPhoneValidationMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useGetPhoneValidationMutation(
    baseOptions?: Apollo.MutationHookOptions<GetPhoneValidationMutation, GetPhoneValidationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<GetPhoneValidationMutation, GetPhoneValidationMutationVariables>(
        GetPhoneValidationDocument,
        options,
    )
}
export type GetPhoneValidationMutationHookResult = ReturnType<typeof useGetPhoneValidationMutation>
export type GetPhoneValidationMutationResult = Apollo.MutationResult<GetPhoneValidationMutation>
export type GetPhoneValidationMutationOptions = Apollo.BaseMutationOptions<
    GetPhoneValidationMutation,
    GetPhoneValidationMutationVariables
>
export const ValidateAccountDocument = gql`
    mutation validateAccount($code: String!) {
        validate(code: $code)
    }
`
export type ValidateAccountMutationFn = Apollo.MutationFunction<
    ValidateAccountMutation,
    ValidateAccountMutationVariables
>

/**
 * __useValidateAccountMutation__
 *
 * To run a mutation, you first call `useValidateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAccountMutation, { data, loading, error }] = useValidateAccountMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ValidateAccountMutation, ValidateAccountMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<ValidateAccountMutation, ValidateAccountMutationVariables>(
        ValidateAccountDocument,
        options,
    )
}
export type ValidateAccountMutationHookResult = ReturnType<typeof useValidateAccountMutation>
export type ValidateAccountMutationResult = Apollo.MutationResult<ValidateAccountMutation>
export type ValidateAccountMutationOptions = Apollo.BaseMutationOptions<
    ValidateAccountMutation,
    ValidateAccountMutationVariables
>
export const MeDocument = gql`
    query Me {
        me {
            id
            firstName
            lastName
            email
            gender
            avatar
            validated
            phone
            address {
                country
                zip
                line1
                city
            }
        }
    }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const GetBookingsOfUserDocument = gql`
    query getBookingsOfUser {
        getBookingsOfUser {
            id
            from
            to
            court {
                name
                venue {
                    name
                }
            }
            price
        }
    }
`

/**
 * __useGetBookingsOfUserQuery__
 *
 * To run a query within a React component, call `useGetBookingsOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsOfUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookingsOfUserQuery(
    baseOptions?: Apollo.QueryHookOptions<GetBookingsOfUserQuery, GetBookingsOfUserQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetBookingsOfUserQuery, GetBookingsOfUserQueryVariables>(GetBookingsOfUserDocument, options)
}
export function useGetBookingsOfUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBookingsOfUserQuery, GetBookingsOfUserQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetBookingsOfUserQuery, GetBookingsOfUserQueryVariables>(
        GetBookingsOfUserDocument,
        options,
    )
}
export type GetBookingsOfUserQueryHookResult = ReturnType<typeof useGetBookingsOfUserQuery>
export type GetBookingsOfUserLazyQueryHookResult = ReturnType<typeof useGetBookingsOfUserLazyQuery>
export type GetBookingsOfUserQueryResult = Apollo.QueryResult<GetBookingsOfUserQuery, GetBookingsOfUserQueryVariables>
export const StorePartnerDocument = gql`
    mutation StorePartner($mail: String!, $analiticsId: String) {
        storePartnerContact(mail: $mail, analiticsId: $analiticsId)
    }
`
export type StorePartnerMutationFn = Apollo.MutationFunction<StorePartnerMutation, StorePartnerMutationVariables>

/**
 * __useStorePartnerMutation__
 *
 * To run a mutation, you first call `useStorePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storePartnerMutation, { data, loading, error }] = useStorePartnerMutation({
 *   variables: {
 *      mail: // value for 'mail'
 *      analiticsId: // value for 'analiticsId'
 *   },
 * });
 */
export function useStorePartnerMutation(
    baseOptions?: Apollo.MutationHookOptions<StorePartnerMutation, StorePartnerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<StorePartnerMutation, StorePartnerMutationVariables>(StorePartnerDocument, options)
}
export type StorePartnerMutationHookResult = ReturnType<typeof useStorePartnerMutation>
export type StorePartnerMutationResult = Apollo.MutationResult<StorePartnerMutation>
export type StorePartnerMutationOptions = Apollo.BaseMutationOptions<
    StorePartnerMutation,
    StorePartnerMutationVariables
>
export const VenuesDocument = gql`
    query Venues($keyword: String) {
        searchVenues(keyword: $keyword) {
            id
            name
            slug
            companyName
            description
            phoneNumber
            businessHours {
                dayOfWeek
                openTime
                closeTime
            }
            images {
                url
            }
            contactPersonName
            email
            address {
                id
                lat
                lng
                line1
                city
                zip
                state
                country
            }
        }
    }
`

/**
 * __useVenuesQuery__
 *
 * To run a query within a React component, call `useVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useVenuesQuery(baseOptions?: Apollo.QueryHookOptions<VenuesQuery, VenuesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<VenuesQuery, VenuesQueryVariables>(VenuesDocument, options)
}
export function useVenuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenuesQuery, VenuesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<VenuesQuery, VenuesQueryVariables>(VenuesDocument, options)
}
export type VenuesQueryHookResult = ReturnType<typeof useVenuesQuery>
export type VenuesLazyQueryHookResult = ReturnType<typeof useVenuesLazyQuery>
export type VenuesQueryResult = Apollo.QueryResult<VenuesQuery, VenuesQueryVariables>
export const VenueDocument = gql`
    query Venue($idOrSlug: String!) {
        getVenue(idOrSlug: $idOrSlug) {
            id
            name
            slug
            companyName
            phoneNumber
            contactPersonName
            description
            email
            images {
                url
            }
            courts {
                surface
                name
            }
            address {
                id
                lat
                lng
                line1
                city
                zip
                state
                country
            }
        }
    }
`

/**
 * __useVenueQuery__
 *
 * To run a query within a React component, call `useVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueQuery({
 *   variables: {
 *      idOrSlug: // value for 'idOrSlug'
 *   },
 * });
 */
export function useVenueQuery(baseOptions: Apollo.QueryHookOptions<VenueQuery, VenueQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<VenueQuery, VenueQueryVariables>(VenueDocument, options)
}
export function useVenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenueQuery, VenueQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<VenueQuery, VenueQueryVariables>(VenueDocument, options)
}
export type VenueQueryHookResult = ReturnType<typeof useVenueQuery>
export type VenueLazyQueryHookResult = ReturnType<typeof useVenueLazyQuery>
export type VenueQueryResult = Apollo.QueryResult<VenueQuery, VenueQueryVariables>
export const SearchLocationAndVenueDocument = gql`
    query searchLocationAndVenue($keyword: String) {
        searchLocationsAndVenues(keyword: $keyword) {
            ... on Location {
                location
                count
            }
            ... on Venue {
                id
                name
                slug
                images {
                    url
                }
            }
        }
    }
`

/**
 * __useSearchLocationAndVenueQuery__
 *
 * To run a query within a React component, call `useSearchLocationAndVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocationAndVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocationAndVenueQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchLocationAndVenueQuery(
    baseOptions?: Apollo.QueryHookOptions<SearchLocationAndVenueQuery, SearchLocationAndVenueQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<SearchLocationAndVenueQuery, SearchLocationAndVenueQueryVariables>(
        SearchLocationAndVenueDocument,
        options,
    )
}
export function useSearchLocationAndVenueLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchLocationAndVenueQuery, SearchLocationAndVenueQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<SearchLocationAndVenueQuery, SearchLocationAndVenueQueryVariables>(
        SearchLocationAndVenueDocument,
        options,
    )
}
export type SearchLocationAndVenueQueryHookResult = ReturnType<typeof useSearchLocationAndVenueQuery>
export type SearchLocationAndVenueLazyQueryHookResult = ReturnType<typeof useSearchLocationAndVenueLazyQuery>
export type SearchLocationAndVenueQueryResult = Apollo.QueryResult<
    SearchLocationAndVenueQuery,
    SearchLocationAndVenueQueryVariables
>
export const GetBookableSlotsDocument = gql`
    query getBookableSlots($venueId: String!, $date: DateTime!, $length: Float!) {
        getBookableSlots(venueId: $venueId, date: $date, length: $length) {
            id
            venueId
            from
            to
            price
        }
    }
`

/**
 * __useGetBookableSlotsQuery__
 *
 * To run a query within a React component, call `useGetBookableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookableSlotsQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      date: // value for 'date'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGetBookableSlotsQuery(
    baseOptions: Apollo.QueryHookOptions<GetBookableSlotsQuery, GetBookableSlotsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetBookableSlotsQuery, GetBookableSlotsQueryVariables>(GetBookableSlotsDocument, options)
}
export function useGetBookableSlotsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBookableSlotsQuery, GetBookableSlotsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetBookableSlotsQuery, GetBookableSlotsQueryVariables>(GetBookableSlotsDocument, options)
}
export type GetBookableSlotsQueryHookResult = ReturnType<typeof useGetBookableSlotsQuery>
export type GetBookableSlotsLazyQueryHookResult = ReturnType<typeof useGetBookableSlotsLazyQuery>
export type GetBookableSlotsQueryResult = Apollo.QueryResult<GetBookableSlotsQuery, GetBookableSlotsQueryVariables>
export const CreateBookingDocument = gql`
    mutation createBooking($venueId: String!, $from: DateTime!, $to: DateTime!, $price: Float!) {
        createBooking(venueId: $venueId, from: $from, to: $to, price: $price, comment: "") {
            id
        }
    }
`
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCreateBookingMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options)
}
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
>
export const CanCreateBookingDocument = gql`
    query canCreateBooking($venueId: String!, $from: DateTime!, $to: DateTime!, $price: Float!) {
        canCreateBooking(venueId: $venueId, from: $from, to: $to, price: $price, comment: "")
    }
`

/**
 * __useCanCreateBookingQuery__
 *
 * To run a query within a React component, call `useCanCreateBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanCreateBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanCreateBookingQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCanCreateBookingQuery(
    baseOptions: Apollo.QueryHookOptions<CanCreateBookingQuery, CanCreateBookingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CanCreateBookingQuery, CanCreateBookingQueryVariables>(CanCreateBookingDocument, options)
}
export function useCanCreateBookingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CanCreateBookingQuery, CanCreateBookingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<CanCreateBookingQuery, CanCreateBookingQueryVariables>(CanCreateBookingDocument, options)
}
export type CanCreateBookingQueryHookResult = ReturnType<typeof useCanCreateBookingQuery>
export type CanCreateBookingLazyQueryHookResult = ReturnType<typeof useCanCreateBookingLazyQuery>
export type CanCreateBookingQueryResult = Apollo.QueryResult<CanCreateBookingQuery, CanCreateBookingQueryVariables>
